import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import StaticTemplate from '@templates/StaticTemplate';
import { Footer, Header, Page } from '@components';

export const pageQuery = graphql`
  query PrivacyPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/privacy.md/" }) {
      html
      frontmatter {
        title
        details
      }
    }
  }
`

const PrivacyPage = ({ data }) => (
  <Page>
    <SEO title="Privacy policy" />
    <Header />
      <StaticTemplate data={data} />
    <Footer />
  </Page>
)

export default PrivacyPage
